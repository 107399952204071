import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; 
import API_BASE_URL from '../api/config';
import logo from '../assets/logo512.png';
import '../styles/Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
 
  console.log('API_BASE_URL:', API_BASE_URL);
  const logUserAction = async (userId, message) => {
    try {
      await axios.post(`${API_BASE_URL}/logs.php`, {
        id_utilisateur: userId,
        message: message
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du log:', error.response ? error.response.data.message : error.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/login.php`, {
        usernameOrEmail,
        password
      });

      console.log('Login Response:', response.data);

      if (response.data.role === 'admin') {
        const userId = response.data.id_utilisateur;
        const nom = response.data.nom;
        const prenom = response.data.prenom;
        const email = response.data.email;
        const nom_utilisateur = response.data.nom_utilisateur;
        const userName = `${response.data.prenom} ${response.data.nom}`;
        const logMessage = `Utilisateur connecté au dashboard - ${userName}`;

        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('nom', nom);
        sessionStorage.setItem('prenom', prenom);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('nom_utilisateur', nom_utilisateur);        
        sessionStorage.setItem('userRole', response.data.role); 
        logUserAction(userId, logMessage);

        toast.success('Connexion réussie !', {
          position: 'top-right',
        });

        setTimeout(() => {
          navigate('/');  
        }, 2000); 
      } else {
        toast.error('Vous n\'êtes pas autorisé à accéder à ce site.', {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error('Login Error:', error);
      if (error.response && error.response.status === 401) {
        toast.error('Nom d\'utilisateur, email ou mot de passe incorrect.', {
          position: 'top-right',
        });
      } else {
        toast.error('Une erreur est survenue. Veuillez réessayer plus tard.', {
          position: 'top-right',
        });
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='container-login'>
      <div className='logo'>
        <img src={logo} alt='Logo' />
      </div>
      <div className='form-container'>
        <h2>Connexion</h2>
        <form onSubmit={handleLogin}>
          <div className='form-group'>
            <label htmlFor='usernameOrEmail'>Nom d'utilisateur ou Email</label>
            <input
              className='input-login'
              type='text'
              id='usernameOrEmail'
              name='usernameOrEmail'
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Mot de passe</label>
            <div className='password-container'>
              <input
                className='input-login'
                type={showPassword ? 'text' : 'password'}
                id='password'
                name='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type='button'
                className='toggle-password'
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <button type='submit' className='button-login'>Se connecter</button>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Login;