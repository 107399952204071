import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  
import axios from 'axios';
import API_BASE_URL from '../api/config'; 

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();  

  useEffect(() => {
    const fetchUsers = async () => {
      setIsSearching(true);
      try {
        let url = `${API_BASE_URL}/utilisateurs.php`;
        let params = {};
 
        if (searchTerm.length >= 3) {
          params.search = searchTerm;
        } else {
          params.limit = 10;   
        }

        const response = await axios.get(url, { params });
        const data = response.data;

        if (Array.isArray(data)) { 
          if (searchTerm.length < 3 && data.length > 2) {
            setUsers(data.slice(0, 10));
          } else {
            setUsers(data);
          }
        } else {
          console.error('Format de données inattendu:', data);
          setError('Format de données inattendu.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs', error);
        setError('Erreur lors de la récupération des utilisateurs.');
      }
      setIsSearching(false);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchUsers();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleCardClick = (id) => {
    navigate(`/users/${id}/edit`);
  };

  return (
    <div className="container">
      <div className='title-page'>
        <h2>Utilisateurs</h2>
      </div>
      {error && <p className="error">{error}</p>}
      <input
        type="text"
        placeholder="Entrez un nom complet, nom d'utilisateur complet ou email complet pour rechercher"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      {isSearching ? (
        <p className="loading">Chargement...</p>
      ) : (
        users.length > 0 ? (
          <ul className="users-list">
            {users.map(user => (
              <li
                key={user.id_utilisateur}
                className="user-item"
                onClick={() => handleCardClick(user.id_utilisateur)}
                role="button"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleCardClick(user.id_utilisateur);
                }}
              >
                <div className="user-info">
                  <h3>{user.nom} {user.prenom}</h3>
                  <p>{user.email}</p> 
                  <p className="edit-link">
                    <span className="edit-icon">✏️</span> Modifier
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          searchTerm.length >= 3 ? <p className="no-results">Aucun utilisateur trouvé.</p> : <p className="no-results">Aucun utilisateur affiché. Essayez de rechercher pour afficher des utilisateurs.</p>
        )
      )}
    </div>
  );
};

export default Users;
