import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import API_BASE_URL from '../api/config';

const Logout = () => {
  const navigate = useNavigate();

  const logUserAction = async (userId, userName, message) => {
    try {
      await axios.post(`${API_BASE_URL}/logs.php`, {
        id_utilisateur: userId,
        message: `${message} - ${userName}`
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du log:', error.response ? error.response.data.message : error.message);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const prenom = localStorage.getItem('prenom');
    const nom = localStorage.getItem('nom');
    const userName = `${prenom} ${nom}`;

    if (userId) {
      const logMessage = `Utilisateur déconnecté du dashboard`;
      logUserAction(userId, userName, logMessage);
    }

    localStorage.clear();

    toast.success('Déconnexion en cours !', {
      position: 'top-right',
      autoClose: 2000,  
    });
 
    setTimeout(() => {
      navigate('/login');
    }, 2000); 
  }, [navigate]);

  return (
    <div className="logout-container">
      <h2>Déconnexion en toute sécurité...</h2>
      <ToastContainer />
    </div>
  );
};

export default Logout;
