import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../api/config';
import { ToastContainer, toast } from 'react-toastify';

const EditUser = () => {
  const { id_utilisateur } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {

        const response = await axios.get(`${API_BASE_URL}/utilisateurs.php?id=${id_utilisateur}`);
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Erreur lors de la récupération des informations de l\'utilisateur.');
        setLoading(false);
      }
    };

    fetchUser();
  }, [id_utilisateur]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/utilisateurs.php`, user, {
        headers: { 'Content-Type': 'application/json' }
      }); 
      toast.success('Utilisateur mis à jour avec succès !'); 
    } catch (error) { 
      toast.error('Erreur lors de la mise à jour de l\'utilisateur.');
    }
  };


  const handleDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
      try {
        await axios.delete(`${API_BASE_URL}/utilisateurs.php?id=${id_utilisateur}`);
        toast.success('Utilisateur supprimé avec succès !');
         
        setTimeout(() => {
          navigate('/users');
        }, 1000);
      } catch (error) {
        toast.error('Erreur lors de la suppression de l\'utilisateur.');
      }
    }
  };
  
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  return (
    <div className="container">
      <div className='title-page'>
        <h2>Utilisateurs</h2>
      </div>

      <div className="edit-user-container">
        <h2 className="edit-user-title">Modifier l'utilisateur</h2>
        {user && (
          <form onSubmit={handleUpdate} className="edit-user-form">
            <div className="form-group">
              <label>Nom:</label>
              <input
                type="text"
                name="nom"
                value={user.nom}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Prénom:</label>
              <input
                type="text"
                name="prenom"
                value={user.prenom}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Nom Utilisateur:</label>
              <input
                type="text"
                name="nom_utilisateur"
                value={user.nom_utilisateur}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={user.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Rôle:</label>
              <select name="role" value={user.role} onChange={handleChange} required>
                <option value="admin">Admin</option>
                <option value="user">Utilisateur</option>
              </select>
            </div>
            <div className="form-buttons">
              <button type="submit" className="update-button">Mettre à jour</button>
              <button
                type="button"
                onClick={handleDelete}
                className="delete-button"
              >
                Supprimer le compte
              </button>
            </div>
          </form>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000} 
        draggable
        pauseOnHover
      />

    </div>
  );
};

export default EditUser;
