import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';


import Home from './pages/Home';
import Users from './pages/Users';
import Bilans from './pages/Bilans';
import Questionnaire from './pages/Questionnaire';
import Logout from './pages/Logout';
import EditUser from './pages/EditUser';
import Login from './pages/Login';
import './styles/Sidebar.css';
import './styles/Bilans.css';
import './styles/styles.css';
import './styles/Home.css';
import './styles/Dashboard.css';
import './styles/Questionnaire.css';
import './styles/Users.css';
import './styles/EditUser.css';
import './styles/Login.css';



import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = () => {
      const userId = sessionStorage.getItem('userId');
      const userRole = sessionStorage.getItem('userRole');

      if (userId && userRole === 'admin') {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
  }, []);

  return (
    <Router>
      <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <Routes>
          <Route path="/login" element={<Login />} />

          {isAuthenticated ? (
            <Route
              path="*"
              element={
                <div style={{ display: 'flex', flex: 1 }}>
                  <Sidebar />
                  <div className="content" style={{ flex: 1 }}>
                    <Routes>
                    
                      <Route path="/" element={<Home />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/bilans" element={<Bilans />} />
                      <Route path="/questionnaire" element={<Questionnaire />} />
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/users/:id_utilisateur/edit" element={<EditUser />} />
                    </Routes>
                  </div>
                </div>
              }
            />
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
          
        </Routes>

       
      </div>
    </Router>
  );
};

export default App;
