import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../api/config';

const Bilans = () => {
  const [bilans, setBilans] = useState([]);
  const [filteredBilans, setFilteredBilans] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');  

  useEffect(() => {
    const fetchBilans = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/bilans.php`);
        setBilans(response.data);
        setFilteredBilans(response.data);  
      } catch (error) {
        setError('Erreur lors de la récupération des bilans.');
      }
    };

    fetchBilans();
  }, []);

  useEffect(() => { 
    let filtered = [];
    if (filter === 'finished') {
      filtered = bilans.filter(bilan => bilan.etapes_completees === '3/3');
    } else if (filter === 'not-finished') {
      filtered = bilans.filter(bilan => bilan.etapes_completees !== '3/3');
    } else {
      filtered = bilans;  
    }
    setFilteredBilans(filtered);
  }, [filter, bilans]);

  return (
    <div className='container'>
      <div className='title-page'>
        <h2>Bilans</h2>
      </div>
      {error && <p>{error}</p>}
      <div className='filter-container'>
        <button onClick={() => setFilter('all')}>Tous</button>
        <button onClick={() => setFilter('finished')}>Finis</button>
        <button onClick={() => setFilter('not-finished')}>Non finis</button>
      </div>
      <div className='card-container'>
        {filteredBilans.length > 0 ? (
          filteredBilans.map((bilan) => (
            <div className='card' key={bilan.id_bilan}>
              <div className='card-header'>
                <span className='steps-completed'>{bilan.etapes_completees}</span>
              </div>
              <h3>{bilan.prenom} {bilan.nom}</h3>
              <p><strong>Nom d'utilisateur:</strong> {bilan.nom_utilisateur}</p>
              <p><strong>Email:</strong> {bilan.email}</p>
            </div>
          ))
        ) : (
          <p>Aucun bilan trouvé.</p>
        )}
      </div>
    </div>
  );
};

export default Bilans;
