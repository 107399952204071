import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaHome, FaUsers, FaChartBar, FaClipboardList, FaSignOutAlt } from 'react-icons/fa';

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <button className="collapse-toggle" onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? <FaArrowRight /> : <FaArrowLeft />}
      </button>
      <ul>
        <li><Link to="/"><FaHome /> {!collapsed && 'Accueil'}</Link></li>
        <li><Link to="/users"><FaUsers /> {!collapsed && 'Utilisateurs'}</Link></li>
        <li><Link to="/bilans"><FaChartBar /> {!collapsed && 'Bilans'}</Link></li>
        <li><Link to="/questionnaire"><FaClipboardList /> {!collapsed && 'Questionnaire'}</Link></li>
        <li><Link to="/logout"><FaSignOutAlt /> {!collapsed && 'Déconnexion'}</Link></li>
      </ul>
    </div>
  );
};

export default Sidebar;
