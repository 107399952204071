import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUsers, FaChartLine, FaDollarSign } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import API_BASE_URL from '../api/config'; 

const monthNames = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];

 
const Home = () => {
  const [stats, setStats] = useState({
    total_users: 0,
    total_bilans: 0,
    total_revenus: 0,
  });
  const [chartData, setChartData] = useState({ bilans: [], users: [] });
  const [year, setYear] = useState(new Date().getFullYear());
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchStats = async () => {
  //     try {
  //       const response = await axios.get(`${API_BASE_URL}/stats.php`);
  //       setStats(response.data);
  //     } catch (error) {
  //       console.error('Erreur lors de la récupération des statistiques', error);
  //       setError('Erreur lors de la récupération des statistiques.');
  //     }
  //   };

  //   fetchStats();
  // }, []);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/filtered_stats.php`, {
          params: { year }
        });
        setChartData(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données de graphique', error);
        setError('Erreur lors de la récupération des données de graphique.');
      }
    };

    fetchChartData();
  }, [year]);

  const totalRevenus = stats.total_revenus ? stats.total_revenus.toLocaleString() : '0';

  const formatData = (data) => {
    return data.map(d => ({
      period: monthNames[parseInt(d.period.split('-')[1], 10) - 1],
      total: d.total_bilans || d.total_users
    }));
  };

  const bilansData = formatData(chartData.bilans);
  const usersData = formatData(chartData.users);

  return (
    <div className='container'>
      <div className='title-page'>
        <h2>Tableau de bord</h2>
      </div>
      <div className="widget-container">
        <div className="widget">
          <FaUsers size={50} />
          <h3 className="widget-title">Total Utilisateurs</h3>
          <hr />
          <p className="widget-value">{stats.total_users} <br /> <span className='widget-text'> utilisateurs sont inscrits sur le site </span></p>
        </div>

        <div className="widget">
          <FaChartLine size={50} />
          <h3 className="widget-title">Bilans du mois</h3>
          <hr />
          <p className="widget-value">{stats.total_bilans} <br /> <span className='widget-text'> bilans ont été réalisés ce mois-ci</span></p>
        </div>

        <div className="widget">
          <FaDollarSign size={50} />
          <h3 className="widget-title">Revenus</h3>
          <hr />
          <p className="widget-value">${totalRevenus}</p>
        </div>
      </div>

      <div className="filter-container">
        <label>
          Année
        </label><br />
        <select value={year} onChange={(e) => setYear(parseInt(e.target.value))}>
          <option value={2024}>2024</option>
          <option value={2023}>2023</option>
          <option value={2022}>2022</option>
        </select>
      </div>

      <div className="chart-container">
        <h3 className="chart-title">Inscriptions Utilisateurs</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={usersData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="period" />
            <YAxis
              domain={[0, 'auto']}  
              tickFormatter={(value) => value.toFixed(0)}  
            />
            <Tooltip />
            <Line type="monotone" dataKey="total" stroke="#2E498C" />
          </LineChart>
        </ResponsiveContainer>

        <h3 className="chart-title">Bilans Réalisés</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={bilansData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="period" />
            <YAxis 
              domain={[0, 'auto']} 
              tickFormatter={(value) => value.toFixed(0)}  
            />
            <Tooltip />
            <Line type="monotone" dataKey="total" stroke="#2E498C" />
          </LineChart>
        </ResponsiveContainer>
      </div>
       
    </div>
  );
};

export default Home;
