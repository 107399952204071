import React from 'react';

const Questionnaire = () => {
  return (
    <div className='container'>
      <div className='title-page'>
        <h2>Questionnaire</h2>
      </div>
      <p className="maintenance-message">Cette page est en maintenance</p>
    </div>
  );
};

export default Questionnaire;
